<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <v-progress-circular
          v-if="isLoading"
          :size="50"
          :width="10"
          color="#ffbe66"
          indeterminate
      ></v-progress-circular>
      <div v-else>
        <v-alert
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
            width="100%"
            style="position: fixed; z-index: 99999"
        >{{ NOTIFICATION }}
        </v-alert>
        <div class="breadcrumb-container">
          <v-row>
            <v-col cols="12" class="text-left">
              <v-btn class="breadcrumb text-capitalize" text to="/my-page">My Page
              </v-btn>
              <v-icon>mdi-chevron-right</v-icon>
              <v-btn class="text-capitalize breadcrumb"
                     text
                     @click="$router.push('/animations')"
              >
                Animation Library
              </v-btn>
              <v-icon>mdi-chevron-right</v-icon>
              <v-btn class="text-capitalize breadcrumb"
                     text @click="goToCat('parent')">
                {{ categories.parent.title }}
              </v-btn>
              <v-icon>mdi-chevron-right</v-icon>
              <v-btn class="text-capitalize breadcrumb"
                     text @click="goToCat('child')">
                {{ categories.title }}
              </v-btn>
              <v-icon>mdi-chevron-right</v-icon>
              <v-btn class="text-capitalize breadcrumb-current"
                     text>
                {{ title }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="video-container">
          <v-row>
            <v-col cols="7">
              <video-player :component="'animation'" style="border-radius: 1vw!important;" :id="animationId"
                            v-if="animationLoaded"
                            :options="animationOptions"/>
              <v-row>
                <v-col class="text-left mt-5 pr-4" cols="3">
                  <div class="text-title-custom">
                    {{ title }}
                  </div>
                  <div class="text-title-duration">
                    {{ duration }}
                  </div>
                </v-col>
                <v-col cols="9" class="text-right mt-5 pr-4">
                  <v-btn text class="text-icon-custom text-capitalize" @click="bookmarkAnimation()" v-if="bookmark">
                    <v-icon class="mr-1" size="1.5vw">mdi-bookmark</v-icon>
                    Bookmark
                  </v-btn>
                  <v-btn text class="text-icon-custom text-capitalize" @click="bookmarkAnimation()" v-else>
                    <v-icon class="mr-1" size="1.5vw">mdi-bookmark-outline</v-icon>
                    Bookmark
                  </v-btn>
                  <comment class="d-inline-block">
                    <template v-slot:button>
                      <v-btn text class="text-icon-custom text-capitalize">
                        <v-icon class="mr-1" size="1.5vw">mdi-message</v-icon>
                        Note
                      </v-btn>
                    </template>
                  </comment>
                  <report class="d-inline-block" :entity_id="$route.params.id" entity_type="animation">
                    <template v-slot:button>
                      <v-btn text class="text-icon-custom text-capitalize">
                        <v-icon class="mr-1" size="1.5vw">mdi-text</v-icon>
                        Report
                      </v-btn>
                    </template>
                  </report>
                  <v-btn text class="text-icon-custom text-capitalize" to="/bookmarks/animation">
                    <v-icon class="mr-1" size="1.5vw">mdi-animation</v-icon>
                    My Animations
                  </v-btn>
                </v-col>

                <v-col cols="12" class="text-right mt-2 pr-4">
                  <v-btn elevation="0" color="#f2f3f7" class="text-capitalize custom-radius-button"
                         :to="'/related/books/' + $route.params.id">
                    <v-icon class="mr-1" size="1.5vw">mdi-bookshelf</v-icon>
                    Book Page
                  </v-btn>
                  <!--                <v-btn elevation="0" color="#f2f3f7" class="text-capitalize custom-radius-button"-->
                  <!--                       :to="'/related/questions/' + $route.params.id">-->
                  <!--                  <v-icon class="mr-1" size="1.5vw">mdi-help</v-icon>-->
                  <!--                  Related Questions-->
                  <!--                </v-btn>-->
                  <v-btn elevation="0" color="#f2f3f7" class="text-capitalize custom-radius-button"
                         :to="'/related/videos/' + $route.params.id">
                    <v-icon class="mr-1" size="1.5vw">mdi-video</v-icon>
                    Related Videos
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="background-playlist">
              <v-row v-for="playlistItem in playlist" :key="'pl'+playlistItem.slug" cols="12" class="playlist-item"
                     @click="$router.push('/animation/' + playlistItem.slug);$router.go(0)"
                     style="cursor: pointer!important;">
                <v-col cols="6">
                  <v-img class="playlist-item-image" :src="baseURL + playlistItem.image"></v-img>
                </v-col>
                <v-col cols="6">
                  <div class="play-list-item-title">
                    {{ playlistItem.title }}
                  </div>
                  <div class="play-list-item-duration">
                    {{ playlistItem.duration_text }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div v-else style="margin-top: 7vh">
      <v-progress-circular
          v-if="isLoading"
          size="90%"
          width="5"
          color="#ffbe66"
          indeterminate
      ></v-progress-circular>
      <div v-else>
        <v-alert
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
            width="100%"
            style="position: fixed; z-index: 99999"
        >{{ NOTIFICATION }}
        </v-alert>
        <v-row class="breadcrumb-container-mobile">
          <v-col cols="12" class="text-left">
            <v-btn
                style="margin-left: -8px"
                class="breadcrumb-mobile text-capitalize" text to="/my-page">My Page
            </v-btn>
            <v-icon small style="margin-left: -20px">mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb-mobile"
                   style="margin-left: -20px"
                   text
                   @click="$router.push('/animations')"
            >
              Animation Library
            </v-btn>
            <v-icon small style="margin-left: -20px">mdi-chevron-right</v-icon>
            <v-btn class="text-capitalize breadcrumb-mobile"
                   style="margin-left: -19px"
                   text @click="goToCat('parent')">
              {{ categories.parent.title }}
            </v-btn>
            <v-icon small style="margin-left: -20px">mdi-chevron-right</v-icon>
            <v-btn
                style="margin-left: -19px"
                class="text-capitalize breadcrumb-mobile"
                text @click="goToCat('child')">
              {{ categories.title }}
            </v-btn>
            <v-icon small style="margin-left: -20px">mdi-chevron-right</v-icon>
            <v-btn style="margin-left: -19px"
                   class="text-capitalize breadcrumb-current-mobile"
                   text>
              {{ title }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-7">
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="menu-bg-mobile">
            <v-row>
              <v-col cols="2" class="white--text ml-3" @click="bookmarkAnimation()">
                Bookmark
              </v-col>
              <v-col cols="2" class="white--text">
                <comment class="d-inline-block">
                  <template v-slot:button>
                    <v-btn text class="white--text text-capitalize"
                           style="letter-spacing: normal;margin-left: -2px;margin-top: -7px;font-family: Blinker-Bold!important;font-size: 17px">
                      Note
                    </v-btn>
                  </template>
                </comment>
              </v-col>
              <v-col cols="2" class="white--text">
                <report class="d-inline-block" :entity_id="$route.params.id" entity_type="animation">
                  <template v-slot:button>
                    <v-btn text class="white--text text-capitalize"
                           style="letter-spacing: normal;margin-left: -18px;margin-top: -7px;font-family: Blinker-Bold!important;font-size: 17px">
                      Report
                    </v-btn>
                  </template>
                </report>
              </v-col>
              <v-col cols="4" class="white--text text-left" @click="$router.push('/bookmarks/animation')">
                My Animations
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="menu-bg-mobile">
            <v-row>
              <v-col cols="6" class="white--text" style="font-size: 17px"
                     @click="$router.push('/related/videos/'+$route.params.id)"
              >
                Related Videos
              </v-col>
              <v-col cols="6" class="white--text text-left" style="font-size: 17px"
                     @click="$router.push('/related/books/'+$route.params.id)"
              >
                Book Page
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-8 mb-16">
          <v-col cols="12">
            <video-player :component="'animation-mobile'" style="border-radius: 1vw!important;" :id="animationId"
                          v-if="animationLoaded"
                          :options="animationOptions"/>
          </v-col>
        </v-row>
        <v-row v-for="playlistItem in playlist" :key="'pl'+playlistItem.slug" cols="12" class="playlist-item"
               @click="$router.push('/animation/' + playlistItem.slug);$router.go(0)"
               style="cursor: pointer!important;">
          <v-col cols="6">
            <v-img class="playlist-item-image" :src="baseURL + playlistItem.image"></v-img>
          </v-col>
          <v-col cols="6">
            <div class="play-list-item-title">
              {{ playlistItem.title }}
            </div>
            <div class="play-list-item-duration">
              {{ playlistItem.duration_text }}
            </div>
          </v-col>
        </v-row>
        <v-row class="my-16">
          <v-col></v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VideoPlayer from '@/components/VideoPlayer.vue'
import Comment from "../components/Comment";
import Report from "../components/Report";

export default {
  name: "AnimationExample",
  components: {
    VideoPlayer,
    Comment,
    Report
  },
  data() {
    return {
      screenType: null,
      categories: null,
      playlist: [],
      bookmark: null,
      title: null,
      duration: null,
      content: null,
      isLoading: true,
      dialog: false,
      animationPath: '',
      animationId: '',
      animationLoaded: false,
      comments: null,
      newComment: {
        title: null,
        body: null,
      },
      animationOptions: {
        playbackRates: [0.5, 1, 1.5, 2, 4, 8],
        autoplay: true,
        height: 500,
        width: 888,
        controls: true,
        liveui: true,
        responsive: true,
        sources: [
          {
            src: '',
            type: "application/vnd.apple.mpegurl"
          }
        ],
      }
    }
  },
  beforeMount() {
    this.getData()
  },
  mounted() {
    this.getScreenType()
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    goToCat(type) {
      this.$router.push({
        name: 'Animations', params: {
          category: type === 'child' ? this.categories : this.categories.parent,
          type: type,
          selectedCategoryTitle: this.categories.parent.title,
          selectedSubCategoryTitle: this.categories.title,
        }
      })
    },
    storeComment() {
      window.axios.post(`api/comment/${this.$route.params.id}`, {
        title: this.newComment.title,
        body: this.newComment.body
      }).then(() => {
        this.NOTIFICATION = 'Comment Stored Successfully!';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'green'
      })
    },
    getComments() {
      window.axios.get(`api/comments/${this.$route.params.id}`).then((res) => {
        this.comments = res.data.data
      })
    },
    bookmarkAnimation() {
      window.axios.post(`api/bookmarks/${this.$route.params.id}`).then(() => {
        this.bookmark = !this.bookmark
      })
    },
    getData() {
      this.animationLoaded = false
      this.animationId = this.$route.params.id
      window.axios.get('api/animations/animation/' + this.$route.params.id).then((res) => {
        this.categories = res.data.data.categories[0]
        this.animationPath = res.data.data.path
        this.title = res.data.data.title
        this.duration = res.data.data.duration_text
        this.content = res.data.data.content
        this.animationOptions.sources[0]['src'] = this.baseURL + res.data.data.path
        this.comments = res.data.data.comments
        this.isLoading = false
        this.bookmark = res.data.data.bookmarked
        this.animationLoaded = true
      }).catch((error) => {
        if (error.response.status == 401) {
          return
        }
        this.NOTIFICATION = 'You must have a digital plan to view products.';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'red'
        this.isLoading = false
        let _this = this
        setTimeout(() => {
          _this.$router.push('/plans')
        }, 2000)
      })
      window.axios.get('api/animations/playlist/' + this.$route.params.id).then((res) => {
        this.playlist = res.data.data
      })
    },
  }
}
</script>
<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.background-theme {
  font-family: Blinker-SemiBold;
  border-radius: 20px;
  padding: 10px;
  background-color: #f2f3f7;

}

.menu-bg-mobile {
  background-color: #a8a8a8;
}

.breadcrumb-container-mobile {
  position: absolute;
  z-index: 10;
  width: 104%;
  left: 0;
  top: 80px;
  height: 15vh;
  background-color: #f6f6f9 !important;
}

.breadcrumb-mobile {
  font-size: 13px;
  color: #848e99;
}

.breadcrumb-current-mobile {
  font-size: 13px;
  color: #39bf88;
}

.breadcrumb {
  font-size: 0.9vw !important;
  color: #848e99;
}

.breadcrumb-current {
  font-size: 0.9vw !important;
  color: #39bf88;
}

.video-container {
  margin-top: 1vh !important;
  margin-left: 7vw !important;
  margin-bottom: 10vh !important;
}

.breadcrumb-container {
  margin-top: -6vh !important;
  margin-left: 8.1vw !important;
}

.background-playlist {
  height: 53vh !important;
  margin-top: 1.1vh !important;
  margin-bottom: 2vh !important;
  background-color: #f2f3f7 !important;
  border-radius: 1vw !important;
  overflow-y: scroll !important;
}

.playlist-item {
  border-radius: 1vw !important;
  margin: 0.5vh 0vw !important;
}

.playlist-item:hover {
  background-color: white !important;
}

.playlist-item-image {
  height: 9.5vh !important;
  border-radius: 10px !important;
}

.text-icon-custom {
  color: #a8a8a8 !important;
  font-size: 0.8vw !important;
}

.text-icon-custom:hover {
  color: #34c288 !important;
  background-color: white !important;
}

.custom-radius-button {
  color: #a8a8a8 !important;
  border-radius: 0.8vw !important;
  padding: 1.2vw !important;
  margin-right: 0.8vw !important;
  font-size: 1vw !important;
}

.custom-radius-button:hover {
  color: white !important;
  background-color: #34c288 !important;
}

.text-title-custom {
  font-size: 1.2vw !important;
  color: #6d6d6d !important;
}

.text-title-duration {
  font-size: 0.8vw !important;
  color: #a8a8a8 !important;
}

.play-list-item-title {
  text-align: left !important;
  font-size: 16px !important;
  color: #6d6d6d !important;
}

.play-list-item-duration {
  text-align: left !important;
  font-size: 14px !important;
  color: #a8a8a8 !important;
}

/*.margin-x-custom-video-under-bar{*/
/*  margin-top:-1vh!important;*/
/*  margin-left: -1vw!important;*/
/*  margin-right: 5vw!important;*/
/*}*/
</style>