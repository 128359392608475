<template>
  <div>
    <div>
    </div>

    <v-dialog
        style="z-index: 99999"
        v-model="dialog"
        width="697px"
        max-width="697px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
            v-bind="attrs"
            v-on="on"
        >
          <slot name="button"></slot>
        </div>
      </template>
      <v-btn @click="dialog = false" class="close-comment-dialog"><v-icon color="#a8a8a8">mdi-close</v-icon></v-btn>
      <v-card>
        <v-alert
            v-model="NOTIFICATION_STATUS"
            :color="NOTIFICATION_COLOR"
            elevation="24"
            type="success"
            dismissible
            style="position: fixed; z-index: 99999; left: 0; margin-left: 300px; margin-right: 300px; right: 0"
        >{{ NOTIFICATION }}
        </v-alert>
        <v-card-title>
          <span class="comment-card-title">Note</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
              style="margin-bottom: 40px"
              placeholder="Topic"
              rounded
              outlined
              hide-details
              v-model="title"
              color="black"
          >
          </v-text-field>
          <v-textarea
              placeholder="Note"
              color="black"
              outlined
              rounded
              no-resize
              height="176px"
              v-model="body"
              hide-details>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn @click="storeComment" :disabled="!(title && body)" class="comment-green-btn" rounded>
            Save
          </v-btn>

          <v-dialog
              style="z-index: 99999"
              v-model="allCommentsDialog"
              width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="getComments"
                  class="yellow-green-btn"
                  rounded>
                See Notes
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="comment-card-title">Comments</span>
              </v-card-title>
              <v-card-text v-for="comment in comments" :key="comment.id">
                <h4 style="text-align: left; direction: ltr">{{ comment.title }}</h4>
                <p style="text-align: left; direction: ltr">{{ comment.body }}</p>
                <p style="text-align: right; direction: rtl">{{ comment.created_at }}</p>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="allCommentsDialog = false"
                    class="yellow-green-btn"
                    rounded>
                  Close
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Comment",

  data() {
    return {
      dialog: false,
      allCommentsDialog: false,
      title: null,
      body: null,
      comments: null,
    }
  },

  methods: {
    storeComment() {
      window.axios.post(`api/comment/${this.$route.params.id}`, {
        title: this.title,
        body: this.body
      }).then(() => {
        this.NOTIFICATION = 'Comment Stored Successfully!';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'green'
        setTimeout(() => {
          this.dialog = false
        }, 2000)
      })
    },
    getComments() {
      window.axios.get(`api/comments/${this.$route.params.id}`).then((res) => {
        this.comments = res.data.data
      })
    },
  }
}
</script>

<style scoped>
.v-card {
  background-color: #f2f3f7;
}

.v-card__title {
  padding-top: 50px!important;
  padding-bottom: 50px!important;
  padding-left: 79px!important;
  padding-right: 79px!important;
}
.v-card__text {
  padding-bottom: 40px!important;
  padding-left: 79px!important;
  padding-right: 79px!important;
}
.v-card__actions {
  padding-bottom: 60px!important;
  padding-left: 79px!important;
  padding-right: 79px!important;
}

.comment-card-title {
  font-family: Blinker-SemiBold;
  color: #6d6d6d;
  font-size: 16px;
}

.comment-green-btn {
  background-color: #48b284!important;
  opacity: 100!important;
  box-shadow: none!important;
  font-size: 16px!important;
  font-family: Blinker-SemiBold!important;
  color: #ffffff!important;
  width: 180px!important;
  height: 60px!important;
}

.yellow-green-btn {
  background-color: #fdbd68!important;
  opacity: 100!important;
  box-shadow: none!important;
  font-size: 16px!important;
  font-family: Blinker-SemiBold!important;
  color: #ffffff!important;
  width: 180px!important;
  height: 60px!important;
  margin-left: 18px;
}

.close-comment-dialog {
  background-color: #f2f3f7!important;
  width: 50px!important;
  min-width: 50px!important;
  height: 50px!important;
  padding-right: 0!important;
  padding-left: 0!important;
  border-radius: 10px!important;
  box-shadow: none;
  position: absolute;
  right: calc(((100vw - 697px) / 2) - 70px);
}
</style>